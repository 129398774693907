import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LinearGraph, InfoBox } from 'hosman-material';
import { D_TriangleExclamation } from 'hosman-material';

import TitleBanner from '../components/TitleBanner';

const MarketAnalysis = ({ estimationFolder }) => {
  const [items, setItems] = useState([]);
  const [priceSeries, setPriceSeries] = useState([])
  const [publicationSeries, setPublicationSeries] = useState([])
  const [stockSeries, setStockSeries] = useState([])

  useEffect(() => {
    estimationFolder.price_data && setPriceSeries([{
      name: 'Prix / m²',
      data: formatSeries(estimationFolder.price_data.points)
    }])

    estimationFolder.pub_duration_data && setPublicationSeries([{
      name: 'Jours de publication',
      data: formatSeries(estimationFolder.pub_duration_data.points)
    }])

    estimationFolder.stock_data && setStockSeries([{
      name: 'Stock',
      data: formatSeries(estimationFolder.stock_data.points)
    }])
  }, [estimationFolder]);

  useEffect(() => {
    const items = ([
      { id: 2, name: `Évolution des durées de publication (${estimationFolder.sale.property.zip_code})`, blink: true, series: publicationSeries, xAxis: {title: 'Jours',label: 'j'}, infoText: "Cette courbe représente l'évolution des durées de publication par trimestre. Elle est réalisée à partir des biens dont les annonces sont retirées des portails immobiliers pour chaque trimestre." },
      { id: 3, name: `Évolution du stock des ${estimationFolder.sale.property.room_number} pièce${estimationFolder.sale.property.room_number > 1 ? 's' : ''} (${estimationFolder.sale.property.zip_code})`, blink: true, series: stockSeries, xAxis: {title: 'Stock', label: ''}, infoText: `Cette courbe montre l'évolution des stocks de biens commercialisés de ${estimationFolder.sale.property.room_number} pièce${estimationFolder.sale.property.room_number > 1 ? 's' : ''} par trimestre dans le même code postal que votre bien. Le stock du trimestre correspond aux biens qui ont été commercialisés au moins un jour pendant le trimestre.` },
    ])

    if (estimationFolder.price_data) {
      items.unshift({
        id: 1, name: `Évolution des prix au m² (${estimationFolder.sale.property.zip_code})`, series: priceSeries, xAxis: {title: 'Prix / m²',label: '€'}, infoText:
          <div className='flex flex-col gap-3'>
            <p>Cette courbe montre l'évolution des prix au m² par trimestre dans le même code postal que votre bien. Elle est réalisée à partir des biens vendus comparables au vôtre sur cette zone.</p>

            <div className='flex gap-3 item-center'>
              <FontAwesomeIcon icon={D_TriangleExclamation} className='text-ih-orange' size='xl' />
              <p>Attention, ces valeurs sont des moyennes calculées à partir des prix de vente de biens ayant des qualités intrinsèques très variées. Il se peut que le prix au m² que je vous recommande pour votre bien soit différent.</p>
            </div>
          </div>
      })
    }

    setItems(items)
  }, [priceSeries, publicationSeries]);

  const formatSeries = (points) => {
    const data = points.map((point) => {
      return { x: new Date(point.x).getTime(), y: point.y.values ? Object.values(point.y.values)[0] : point.y.count }
    })
    // Remove last object because it is to young to be reliable
    data.pop();

    return data;
  }

  return (
    <div className='flex flex-col' data-testid='MarketAnalysis'>
      <TitleBanner number='02' title='Analyse du marché' />

      <div className='relative flex flex-col gap-12 bg-ih-purple-5 py-10' id='Anchor-Graph'>
        <p className='h2 text-center md:w-1/2 mx-auto'>Quelques chiffres clés sur <span className='text-ih-blue'>l'immobilier dans votre secteur</span></p>

        {items.map(item => {
          return (
            <div key={item.id} className='flex flex-col gap-6'>
              <p className='h3 text-center text-ih-blue'>{item.name}</p>

              <div className='flex flex-col gap-6'>
                {process.env.NODE_ENV !== 'test' &&
                  <div className='md:w-1/2 mx-auto'>
                    <LinearGraph
                      series={item.series}
                      xAxis={item.xAxis}
                    />
                  </div>
                }

                {item.infoText &&
                  <div className='w-3/4 mx-auto'>
                    <InfoBox avatarSrc={estimationFolder.sale.expert?.user?.profile_pic_url}>
                    {item.infoText}
                    </InfoBox>
                  </div>
                }
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default MarketAnalysis;

MarketAnalysis.propTypes = {
  estimationFolder: PropTypes.object.isRequired,
};
